import React, { Fragment } from 'react';
import { GreenFoamTitle } from "../../../components/_green_foam/_green_foam_title";
import { GreenFoamFeature } from "../../../components/_green_foam/_green_foam_feature";
import { useCurrenDevice } from '../../../hooks/useCurrentDevice';
// ===============
//   I M A G E S
// ===============
import img_ecofriendly from "../../../../static/assets/images/green_foam/green-foam-eco-friendly.svg";
import img_saving from "../../../../static/assets/images/green_foam/green-foam-saving.svg";
import img_temperature from "../../../../static/assets/images/green_foam/green-foam-temperature.svg";
import img_america from "../../../../static/assets/images/green_foam/green-foam-america.svg";
// ===============
//   I M A G E S
// ===============

export const GreenFoamWhatIs = ( ) => {

    const [device] = useCurrenDevice();

    return (
        <div className="gf-whatIs">
            <GreenFoamTitle title="¿Qué es <strong>green foam</strong>?"/>
            <p className="gf-whatIs__description">El <strong>GREEN FOAM</strong> tiene fuerza estructural, buena adhesión a superficies de metal con excelentes propiedades aislantes y estabilidad térmica. Ofrece el mejor desempeño y economía con un menor impacto en el medio ambiente.</p>
            <div className="gf-whatIs__features">
                <div className="gf-whatIs__features__left">
                    { device === "desktop" ?
                        <Fragment>
                            <GreenFoamFeature icon={ img_ecofriendly } description="El nuevo aislante térmico <strong>eco-amigable</strong> de <strong>Calorex.</strong>" />
                            <GreenFoamFeature icon={ img_temperature } description="<strong>Conserva el calor</strong> manteniendo la temperatura por más tiempo."/>
                        </Fragment>
                        :
                        <Fragment>
                            <GreenFoamFeature icon={ img_ecofriendly } description="El nuevo aislante térmico <strong>eco-amigable</strong> de <strong>Calorex.</strong>" />
                            <GreenFoamFeature icon={ img_saving } description="*Ahorra hasta el <strong>55% del gas</strong> consumido por reencendidas."/>
                        </Fragment>
                    }
                </div>
                <div className="gf-whatIs__features__right">
                    { device === "desktop" ?
                        <Fragment>
                            <GreenFoamFeature icon={ img_saving } description="*Ahorra hasta el <strong>55% del gas</strong> consumido por reencendidas."/>
                            <GreenFoamFeature icon={ img_america } description="Tecnología exclusiva de <strong>Calorex</strong> en <strong>América.</strong>"/>
                        </Fragment>
                        :
                        <Fragment>
                            <GreenFoamFeature icon={ img_temperature } description="<strong>Conserva el calor</strong> manteniendo la temperatura por más tiempo."/>
                            <GreenFoamFeature icon={ img_america } description="Tecnología exclusiva de <strong>Calorex</strong> en <strong>América.</strong>"/>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
};
