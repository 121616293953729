import React, { Fragment } from 'react';
import { GreenFoamTitle } from '../../../components/_green_foam/_green_foam_title';
import { GreenFoamBenefit } from '../../../components/_green_foam/_green_foam_benefit';
import { useCurrenDevice } from '../../../hooks/useCurrentDevice';
// ===============
//   I M A G E S
// ===============
import img_long_baths from "../../../../static/assets/images/green_foam/long-hot-baths.svg";
import img_saving_money from "../../../../static/assets/images/green_foam/save-money.svg";
import img_eco_home from "../../../../static/assets/images/green_foam/eco-home.svg";
import img_performance from "../../../../static/assets/images/green_foam/performance.svg";
import img_environment from "../../../../static/assets/images/green_foam/environment.svg";
import img_solucion_int from "../../../../static/assets/images/green_foam/solucion-int.svg";
import img_eco_technology from "../../../../static/assets/images/green_foam/eco-technology.svg";
import img_eficiency from "../../../../static/assets/images/green_foam/eficiency.svg";
import img_calentador from "../../../../static/assets/images/green_foam/green-foam-calentador.png";
// ===============
//   I M A G E S
// ===============

export const GreenFoamBenefits = ( ) => {

    const [device] = useCurrenDevice();

    return (
        <div className="gf-benefits">
            <div className="gf-benefits__grandient"></div>
            <GreenFoamTitle title="Beneficios <strong>Green Foam</strong>"/>
            <div className="gf-benefits__container">
                <div className="gf-benefits__column">
                    { device === "mobile" ?
                        <Fragment>
                            <GreenFoamBenefit icon={ img_long_baths } description="Baños más calientes y más largos." />
                            <GreenFoamBenefit icon={ img_saving_money } description="Ahorra más dinero ahorrando más gas." />
                            <GreenFoamBenefit icon={ img_eco_home } description="Un hogar amigable con el medio ambiente" />
                            <GreenFoamBenefit icon={ img_performance } description="Tiene un mejor desempeño" />
                        </Fragment>
                        :
                        <Fragment>
                            <GreenFoamBenefit icon={ img_long_baths } description="Baños más calientes y más largos." />
                            <GreenFoamBenefit icon={ img_eco_home } description="Un hogar amigable con el medio ambiente" />
                            <GreenFoamBenefit icon={ img_environment } description="El mejor aislante térmico que no causa impacto en el medio ambiente." />
                            <GreenFoamBenefit icon={ img_eco_technology } description="Eco-Tecnología buscamos preservar los recursos naturales." />
                        </Fragment>
                    }
                </div>
                <div className="gf-benefits__column">
                    <img src={ img_calentador } alt="Green Foam Calentador"/>
                </div>
                <div className="gf-benefits__column">
                    { device === "mobile" ?
                        <Fragment>
                            <GreenFoamBenefit icon={ img_environment } description="El mejor aislante térmico que no causa impacto en el medio ambiente." />
                            <GreenFoamBenefit icon={ img_solucion_int } description="Solución inteligente y sustentable." />
                            <GreenFoamBenefit icon={ img_eco_technology } description="Eco-Tecnología buscamos preservar los recursos naturales." />
                            <GreenFoamBenefit icon={ img_eficiency } description="Mejora la eficiencia energética y reduce las emisiones de CO2." />
                        </Fragment>
                        :
                        <Fragment>
                            <GreenFoamBenefit icon={ img_saving_money } description="Ahorra más dinero ahorrando más gas." />
                            <GreenFoamBenefit icon={ img_performance } description="Tiene un mejor desempeño" />
                            <GreenFoamBenefit icon={ img_solucion_int } description="Solución inteligente y sustentable." />
                            <GreenFoamBenefit icon={ img_eficiency } description="Mejora la eficiencia energética y reduce las emisiones de CO2." />
                        </Fragment>
                    }
                </div>
            </div>
            <div className="gf-benefits__footer">
                <p>*Comparado con calentadores que cuentan con aislante térmico de fibra de vidrio.</p>
            </div>
        </div>
    )
};
