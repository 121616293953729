import React from 'react';
import imgTitle from '../../../static/assets/images/green_foam/green-foam-title-icon.svg';
import '../../styles/_components/_green_foam/_title.scss';

export const GreenFoamTitle = ({ title }) => {
    return (
        <div className="gf-title">
            <img src={ imgTitle } alt="Green Foam Icon Title"/>
            <h2 dangerouslySetInnerHTML={{__html:title}} />
        </div>
    )
};
