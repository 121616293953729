import React from 'react';
import { Picture } from 'react-responsive-picture';
import { ByCalorexLogo } from '../../../components/svg/commons/by_calorex_logo';
// ===============
//   I M A G E S
// ===============
import img_tree_leaf from "../../../../static/assets/images/green_foam/green_foam_tree_leaf.png";
import img_calentador_mb from "../../../../static/assets/images/green_foam/calorex-header-greenfoam-mb.jpg";
import img_calentador_tb from "../../../../static/assets/images/green_foam/calorex-header-greenfoam-tb.jpg";
import img_calentador_1280 from "../../../../static/assets/images/green_foam/calorex-header-greenfoam-1280.jpg";
import img_calentador_lp from "../../../../static/assets/images/green_foam/calorex-header-greenfoam-lp.jpg";
import img_calentador_dk from "../../../../static/assets/images/green_foam/calorex-header-greenfoam-dk.jpg";
// ===============
//   I M A G E S
// ===============

export const GreenFoamBanner = ( ) => {
    return (
        <div className="gf-banner">
            <div className="gf-banner__bg">
                <Picture
                    sources = {[
                    {
                        srcSet: img_calentador_mb,
                        media: "(max-width: 767px)",
                    },
                    {
                        srcSet: img_calentador_tb,
                        media: "(max-width: 1023px)",
                    },
                    {
                        srcSet: img_calentador_1280,
                        media: "(max-width: 1280px)",
                    },
                    {
                        srcSet: img_calentador_lp,
                        media: "(max-width: 1366px)",
                    },
                    {
                        srcSet: img_calentador_dk,
                        media: "(min-width: 1366px)",
                    }
                    ]}
                />
            </div>
            <div className="gf-banner__header">
                <div className="gf-banner__logo">
                    <ByCalorexLogo />
                </div>
                <div className="gf-banner__title">
                    <h1>Green Foam</h1>
                </div>
                <div className="gf-banner__description">
                    <img src={img_tree_leaf} alt="green foam tree leaf"/>
                    <p>El nuevo aislante térmico Green Foam de nuestros calentadores de depósito.</p>
                </div>
            </div>
        </div>
    )
};
