import React from 'react';
import '../../styles/_components/_green_foam/_benefit.scss';

export const GreenFoamBenefit = ({ icon, description }) => {
    return (
        <div className="gf-benefit">
            <div className="gf-benefit__circle">
                <img src={ icon } alt="Green Foam Icon Benefit"/>
            </div>
            <p>{ description }</p>
        </div>
    )
};
