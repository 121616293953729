import React from 'react';
import Layout from "../components/layout";
import "../styles/pages/green_foam/green_foam.scss"
import { GreenFoamBanner } from '../content/green_foam/banner/green_foam_banner';
import { GreenFoamWhatIs } from '../content/green_foam/what_is/green_foam_what_is';
import { GreenFoamEcological } from '../content/green_foam/ecological/green_foam_ecological';
import { GreenFoamBenefits } from '../content/green_foam/benefits/green_foam_benefits';

const GreenFoam = () => {

    return(
        <Layout>
            <GreenFoamBanner />
            <GreenFoamWhatIs />
            <GreenFoamEcological />
            <GreenFoamBenefits />
        </Layout>
    )
}

export default GreenFoam
