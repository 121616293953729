import React from 'react';
import { GreenFoamTitle } from '../../../components/_green_foam/_green_foam_title';
import saving_graphic from '../../../../static/assets/images/green_foam/green-foam-saving-graphic.svg';

export const GreenFoamEcological = ( ) => {
    return (
        <div className="gf-ecological">
            <GreenFoamTitle title="¿Por qué es <strong>ecológico</strong>?"/>
            <div className="gf-ecological__description">
                <p>El nuevo aislante térmico eco-amigable <strong>GREEN FOAM</strong> te permite ayudar al planeta al ahorrar más gas, con baños deliciosos que mantienen la temperatura del agua por más tiempo. Incluso después de la vida útil de tu calentador <strong>¡SIGUE SIENDO ECOAMIGABLE!</strong> ya que al desecharse no genera gases tóxicos ni contamina la capa de ozono.</p>
                <p>En cambio otros que cuentan con aislante térmico tradicional, al momento de desecharse, en su proceso de degradación generan gases contaminantes que contribuyen al calentamiento global.</p>
            </div>
            <div className="gf-ecological__container">
                <div className="gf-ecological__left">
                    <span>55%</span>
                    <img src={ saving_graphic } alt="Green Foam graphic"/>
                </div>
                <div className="gf-ecological__right">
                    <h3><span>Hasta 55% de ahorro</span> en gas consumido por reencendidas.</h3>
                    <p>*Comparado con calentadores que cuentan con aislante térmico de fibra de vidrio.</p>
                </div>
            </div>
        </div>
    )
};
