import React from 'react';
import '../../styles/_components/_green_foam/_feature.scss';

export const GreenFoamFeature = ({ icon, description }) => {
    return (
        <div className="gf-feature">
            <div className="gf-feature__circle">
                <img src={ icon } alt="Green Foam Icon Title"/>
            </div>
            <p dangerouslySetInnerHTML={{__html:description}}/>
        </div>
    )
};
